<template>
    <div class="field-avatar">
        <label for="avatar" class="field-avatar_input">
            <div class="field-avatar_input-image">
                <img
                    class="user_image"
                    :class="{ select: !!selected }"
                    :src="selected || currentPic || $assets.primary.user"
                    alt="Imagen de usuario"
                />
            </div>
        </label>
        <div class="field-avatar__info">
            <span class="field-avatar__text">{{
                $lang.components.formFieldAvatarSelect.having_profile_identifies
            }}</span>
            <button type="button" class="field-avatar_button upload" @click="dispatchClick">
                <img :src="$assets.primary.dashCamera" alt="icon" />
                <span
                    >{{ selected ? 'Cambiar' : 'Subir' }}
                    {{ $lang.components.formFieldAvatarSelect.photo }}</span
                >
            </button>
        </div>
        <input
            id="avatar"
            ref="$input"
            class="hidden"
            type="file"
            accept="image/*"
            @change="(e) => getPicture(e)"
        />
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    optional: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Object as PropType<
            | {
                  file: File
              }
            | undefined
            | null
        >,
    },
    currentPic: {
        type: String,
    },
})
const emits = defineEmits(['update:modelValue'])

const fileComp = computed(() => props.modelValue)

const $input = ref()

const selected = ref(null) as Ref<null | string>

watch(fileComp, (file) => {
    if (file) {
        const reader = new FileReader()

        reader.readAsDataURL(file.file)

        reader.addEventListener('load', (e: FileReaderEventMap['load']) => {
            selected.value = e.target?.result as string
        })
    } else {
        selected.value = null
    }
})
const getPicture = (e: InputEvent | Event) => {
    const ref = e.target as HTMLInputElement
    emits(
        'update:modelValue',
        ref.files?.[0]
            ? {
                  file: ref.files?.[0],
              }
            : null,
    )
}
const dispatchClick = () => {
    ;($input.value as HTMLInputElement).click()
}
</script>

<style lang="postcss" scoped>
.field-avatar {
    @apply flex flex-wrap items-center;
    &_input {
        @apply flex max-w-full items-center justify-start lg:block lg:w-max lg:cursor-pointer;
        &-image {
            @apply h-20 w-20 rounded-full border border-gray-200 lg:h-24 lg:w-24;
            img {
                @apply block h-full w-full rounded-full object-contain p-1.5;
                &.select {
                    @apply object-cover p-0;
                }
            }
        }
    }
    &__info {
        @apply ml-3 flex flex-1 flex-col items-stretch justify-between gap-y-2 rounded-xl p-2 lg:h-24 lg:flex-row lg:items-center lg:px-4 lg:shadow-rounded;
    }
    &__text {
        @apply text-xs text-[#6B6870] lg:text-sm;
    }
    &_button {
        @apply flex w-full items-center justify-center space-x-2 rounded-xl px-4 py-3 text-sm font-medium lg:w-auto;
        img {
            @apply h-5;
        }
        &.upload {
            @apply bg-[#F4F4F9] text-site-primary;
        }
        &.delete {
            @apply bg-red-500 text-white;
        }
    }
}
</style>
