<template>
    <div v-if="AuthStore.UserData" class="dashboard-user-config">
        <div class="hidden lg:block">
            <DashCardRouter :title="title.title" :fas-icon="title.icon" :goback="false" />
        </div>
        <button
            class="block w-full border-t pt-2 lg:hidden"
            @click="() => Router.replace({ query: { tab: undefined } })"
        >
            <DashCardRouter
                title="Mi cuenta"
                fas-icon="fas fa-user-alt"
                :goback="false"
                class="block lg:hidden"
            />
        </button>
        <transition name="slide-from-left">
            <div v-if="$route.query.tab === undefined" class="account-config">
                <DashInputAvatarSelect v-model="form.avatar" :current-pic="AuthStore.UserData.image.url" />
                <div class="data-field">
                    <div class="data-field__item">
                        <div class="item__head" :disabled="AuthStore.UserData.username_verified">
                            <div class="item__info">
                                <p>{{ $lang.pages.dashboardAjustes.username }}</p>

                                <div class="item__value-button">
                                    <span>{{ AuthStore.UserData.username }}</span>
                                    <img
                                        v-if="AuthStore.UserData.username_verified"
                                        :src="$assets.primary.verified"
                                        width="12"
                                        height="12"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <button
                                type="button"
                                class="item__button"
                                @click="
                                    () =>
                                        RootStore.setDashboardModal({
                                            type: 'username',
                                            info: {
                                                callback: (success) => success,
                                            },
                                        })
                                "
                            >
                                {{ $lang.pages.dashboardAjustes.change }}
                            </button>
                        </div>
                    </div>

                    <div class="data-field__item">
                        <div class="item__head">
                            <div class="item__info">
                                <p>{{ $lang.pages.dashboardAjustes.password }}</p>
                                <p :class="{ underline: !AuthStore.UserData.has_password }">
                                    {{
                                        AuthStore.UserData.has_password
                                            ? '**********'
                                            : $lang.pages.dashboardAjustes.set_new_password
                                    }}
                                </p>
                            </div>
                            <button
                                type="button"
                                class="item__button"
                                @click="
                                    () =>
                                        RootStore.setDashboardModal({
                                            type: 'password',
                                            info: {
                                                callback: (success) => success,
                                            },
                                        })
                                "
                            >
                                {{
                                    AuthStore.UserData.has_password
                                        ? $lang.pages.dashboardAjustes.change
                                        : $lang.pages.dashboardAjustes.new_password
                                }}
                            </button>
                        </div>
                    </div>

                    <div class="data-field__item">
                        <div class="item__head" :disabled="AuthStore.UserData.account_created_with_rrss">
                            <div class="item__info">
                                <p>{{ $lang.pages.dashboardAjustes.email }}</p>
                                <div class="item__value-button">
                                    <span>{{ AuthStore.UserData.email }}</span>
                                    <img
                                        v-if="AuthStore.UserData.verified_email"
                                        :src="$assets.primary.verified"
                                        width="12"
                                        height="12"
                                        alt="icon"
                                    />
                                    <span v-else class="text-gray-800">{{
                                        $lang.pages.dashboardAjustes.not_verified
                                    }}</span>
                                </div>
                            </div>
                            <button
                                type="button"
                                class="item__button"
                                @click="
                                    () =>
                                        RootStore.setDashboardModal({
                                            type: 'email',
                                            info: {
                                                callback: (success) => success,
                                            },
                                        })
                                "
                            >
                                {{ $lang.pages.dashboardAjustes.change }}
                            </button>
                        </div>
                    </div>

                    <div
                        v-if="!AuthStore.UserData.verified_email"
                        class="data-field__send-verification-button"
                    >
                        <ButtonGeneric
                            class="verification-button"
                            type="button"
                            :disabled="verificationButtonState.disabled"
                            :info="{
                                text: $lang.pages.dashboardAjustes.resend_email,
                                handler: sendVerificationMail,
                            }"
                        />
                        <img
                            v-show="verificationButtonState.loading"
                            :src="$assets.primary.loading"
                            alt="loading"
                            class="verification-loading spin"
                        />
                    </div>
                </div>

                <div class="border-t border-gray-100 pt-4">
                    <button type="button" class="account-config__delete-account" @click="deleteAccount">
                        <img :src="$assets.primary.trash" alt="icon" />{{
                            $lang.pages.dashboardAjustes.delete_account
                        }}
                    </button>
                </div>
            </div>
        </transition>
        <button
            class="block w-full pt-2 lg:hidden"
            @click="() => Router.replace({ query: { tab: 'notificaciones' } })"
        >
            <DashCardRouter title="Notificaciones" fas-icon="fas fa-bell" :goback="false" />
        </button>
        <transition name="slide-from-left">
            <div v-if="$route.query.tab === 'notificaciones'" class="notifications-config">
                <div class="notifications-config__tabs">
                    <button
                        class="notifications-config__btn"
                        :class="
                            !tabNotifications ? 'bg-site-primary text-white' : 'bg-gray-200 text-gray-900'
                        "
                        @click="tabNotifications = false"
                    >
                        <i class="fas fa-bell"></i>
                        {{ $lang.pages.dashboardAjustes.notifications_web }}
                    </button>
                    <button
                        class="notifications-config__btn"
                        :class="tabNotifications ? 'bg-site-primary text-white' : 'bg-gray-200 text-gray-900'"
                        @click="tabNotifications = true"
                    >
                        <i class="fas fa-envelope"></i>
                        {{ $lang.pages.dashboardAjustes.notifications_email }}
                    </button>
                </div>
                <transition :name="'slide-from-left'">
                    <section v-if="form.web && !tabNotifications" class="notifications-config__content">
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.general }}
                            </h4>
                            <DashInputToggle
                                id="notifications-offers-daily"
                                v-model="form.web.web_new_news_published"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.new_novelties_post }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-weekly"
                                v-model="form.web.web_new_message_in_inbox"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.new_private_message }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.web.web_get_coins"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.coins_received }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.comments }}
                            </h4>

                            <DashInputToggle
                                v-model="form.web.web_answer_to_my_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.someone_answers_to_comment }}
                                </p>
                            </DashInputToggle>

                            <DashInputToggle
                                v-model="form.web.web_many_likes_to_my_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.multiple_likes_to_comment }}
                                </p>
                            </DashInputToggle>

                            <DashInputToggle
                                v-model="form.web.web_like_to_my_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.my_comment_liked }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.offers_coupons }}
                            </h4>
                            <DashInputToggle
                                id="notifications-offers-daily"
                                v-model="form.web.web_first_grade"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.discount_first_grade }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-daily"
                                v-model="form.web.web_popular_250"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.discount_popular_250 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-weekly"
                                v-model="form.web.web_popular_500"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.discount_popular_500 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.web.web_other_offers_popular_500"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.discount_other_popular_500 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.web.web_unpopular"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.discount_not_popular_minus_25 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.web.web_expired_post"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.discount_expired }}
                                </p>
                            </DashInputToggle>

                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.web.web_approved_report"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.report_publication_approved }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.web.web_rejected_report"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.report_publication_rejected }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.specials }}
                            </h4>
                            <p class="notifications-config__heading--description">
                                {{ $lang.pages.dashboardAjustes.follow_telegram }}
                            </p>
                            <a
                                :href="$lang.pages.dashboardAjustes.telegram_url"
                                target="_blank"
                                rel="nofollow"
                                class="notifications-config__telegram"
                            >
                                <i class="fab fa-telegram-plane fa-lg text-white"></i>
                                {{ $lang.pages.dashboardAjustes.go_telegram }}
                            </a>
                            <p class="notifications-config__advice">
                                <i class="fas fa-info-circle text-[#94949B]"></i>
                                {{ $lang.pages.dashboardAjustes.telegram_description }}
                            </p>
                        </section>
                    </section>
                </transition>
                <transition :name="'slide-from-left'">
                    <section v-if="form.email && tabNotifications" class="notifications-config__content">
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.email_offers }}
                            </h4>
                            <DashInputToggle
                                id="notifications-offers-daily"
                                v-model="form.email.daily_offers"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.receive_best_offers_daily }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-weekly"
                                v-model="form.email.weekly_offers"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.receive_best_offers_week }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers-recomendations"
                                v-model="form.email.offers_recommended"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.receive_recomendations }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.newsletter }}
                            </h4>
                            <DashInputToggle
                                id="notifications-newsletter-news"
                                v-model="form.email.newsletter"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.receive_newsletter }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-newsletter-weekly"
                                v-model="form.email.weekly_unread_notifications"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.receive_no_readed_notifications }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.general }}
                            </h4>
                            <DashInputToggle
                                id="notifications-general-novelty"
                                v-model="form.email.new_news_published"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.novelties_published }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-general-message"
                                v-model="form.email.new_message_in_inbox"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.inbox_received }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.comments }}
                            </h4>
                            <DashInputToggle
                                id="notifications-comments-tagged"
                                v-model="form.email.tagged_in_a_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_someone_tags_in_comment }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-comments-answer"
                                v-model="form.email.answer_to_my_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.someone_answers_to_comment }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-comments-answer"
                                v-model="form.email.post_receives_first_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_post_receives_first_comment }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-comments-receiving"
                                v-model="form.email.post_receives_comments"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_post_receives_commments }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-comments-approved"
                                v-model="form.email.approved_comment"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.my_comment_approved }}
                                </p>
                            </DashInputToggle>
                        </section>
                        <section class="notifications-config__section">
                            <h4 class="notifications-config__heading--secondary">
                                {{ $lang.pages.dashboardAjustes.offers_coupons }}
                            </h4>
                            <DashInputToggle
                                id="notifications-offers_coupons-popular"
                                v-model="form.email.popular_250"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_discount_popular_250 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers_coupons-popular500"
                                v-model="form.email.popular_500"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_discount_popular_500 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers_coupons-popular500"
                                v-model="form.email.unpopular"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_discount_minus_25 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers_coupons-verypopular"
                                v-model="form.email.other_offers_popular_500"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_other_discount_popular_500 }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers_coupons-expired"
                                v-model="form.email.expired_post"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_discount_expired }}
                                </p>
                            </DashInputToggle>
                            <DashInputToggle
                                id="notifications-offers_coupons-published"
                                v-model="form.email.published_post"
                                class="notifications-config__inner-check"
                            >
                                <p class="notifications-config__inner-text">
                                    {{ $lang.pages.dashboardAjustes.when_discount_published }}
                                </p>
                            </DashInputToggle>
                        </section>
                    </section>
                </transition>

                <div class="notifications-config__button">
                    <ButtonGeneric
                        :loading="emailLoading || webLoading"
                        :info="{
                            text: $lang.pages.dashboardAjustes.save_settings,
                            handler: tabNotifications ? saveEmailConfig : saveWebConfig,
                        }"
                    ></ButtonGeneric>
                </div>
            </div>
        </transition>
        <button
            class="block w-full pt-2 lg:hidden"
            @click="() => Router.replace({ query: { tab: 'conexion-social' } })"
        >
            <DashCardRouter
                title="Conexión social"
                fas-icon="fas fa-chart-network"
                :goback="false"
                class="block lg:hidden"
            />
        </button>
        <transition name="slide-from-left">
            <div v-if="$route.query.tab === 'conexion-social'" class="social-config">
                <h2 class="social-config__title">{{ $lang.pages.dashboardAjustes.created_accounts }}</h2>
                <ButtonGeneric
                    :info="{
                        icon: $assets.social.googleAccount,
                        text: $lang.pages.dashboardAjustes.google_connected,
                        handler: () => !AuthStore.UserData?.google_account && socialSignIn('google'),
                    }"
                    class="social-config__google-button"
                />
                <p class="social-config__is-connected">
                    <img :src="socialConnected.google.icon" alt="icon" />
                    {{ socialConnected.google.text }}
                </p>
                <ButtonGeneric
                    :info="{
                        icon: $assets.social.facebookAccount,
                        text: $lang.pages.dashboardAjustes.facebook_connected,
                        handler: () => !AuthStore.UserData?.facebook_account && socialSignIn('facebook'),
                    }"
                    class="social-config__facebook-button"
                />
                <p class="social-config__is-connected">
                    <img :src="socialConnected.facebook.icon" alt="icon" />
                    {{ socialConnected.facebook.text }}
                </p>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import type { SocialAuthResponse } from '~/components/authentication/dialogs/SignUp.vue'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

const { $lang, $assets } = useNuxtApp()
const AuthStore = useAuthStore()
const setCookies = useSetCookies()
const RootStore = useRootStore()
const Route = useRoute()
const Router = useRouter()
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const { data: responseData, error } = await useAsyncData('user-preferences-data', async () => {
    const emailPreferences = await $fetch<Api.Responses.User.EmailPreferences>(
        endpoints.user.emailPreferences.path,
        {
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            method: 'GET',
        },
    )
    const webPreferences = await $fetch<Api.Responses.User.WebPreferences>(
        endpoints.user.webPreferences.path,
        {
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            method: 'GET',
        },
    )

    return {
        emailPreferences,
        webPreferences,
    }
})

if (
    !responseData.value?.emailPreferences ||
    !responseData.value?.webPreferences ||
    responseData.value?.emailPreferences.error ||
    responseData.value?.webPreferences.error ||
    error.value ||
    !(
        responseData.value?.emailPreferences.feedback === 'data_success' &&
        responseData.value?.webPreferences.feedback === 'data_success'
    )
) {
    throw createError({
        statusCode: 404,
        message: $lang.pages.dashboardAjustes.preferences_get_error,
    })
}

const emailPreferences = {} as { [key: string]: any }
const webPreferences = {} as { [key: string]: any }

responseData.value.emailPreferences.data.preferences.forEach(
    (preference) => (emailPreferences[preference.action] = Boolean(preference.type)),
)
responseData.value.webPreferences.data.preferences.forEach(
    (preference) => (webPreferences[preference.action] = Boolean(preference.type)),
)

const form = reactive({
    email: reactive(emailPreferences),
    web: reactive(webPreferences),
    avatar: null as null | { file: File },
})

const verificationButtonState = reactive({
    disabled: false,
    loading: false,
})

const emailLoading = ref(false)
const webLoading = ref(false)
const tabNotifications = ref(false)

const title = computed(() => {
    switch (Route.query.tab) {
        case 'conexion-social':
            return {
                title: $lang.pages.dashboardAjustes.social_connection,
                icon: 'fas fa-chart-network',
            }
        case 'notificaciones':
            return {
                title: $lang.pages.dashboardAjustes.notifications,
                icon: 'fas fa-bell',
            }
    }
    return { title: $lang.pages.dashboardAjustes.account, icon: 'fas fa-user-alt' }
})

const socialConnected = computed(() => {
    return {
        google: {
            icon: AuthStore.UserData?.google_account ? $assets.green.connected : $assets.red.disconnected,
            text: AuthStore.UserData?.google_account
                ? `${$lang.pages.dashboardAjustes.google_connected} ` + AuthStore.UserData?.email
                : $lang.pages.dashboardAjustes.google_not_connected,
        },
        facebook: {
            icon: AuthStore.UserData?.facebook_account ? $assets.green.connected : $assets.red.disconnected,
            text: AuthStore.UserData?.facebook_account
                ? `${$lang.pages.dashboardAjustes.facebook_connected} ` + AuthStore.UserData?.email
                : $lang.pages.dashboardAjustes.facebook_not_connected,
        },
    }
})

const userAvatar = computed(() => {
    return form.avatar?.file
})

const updateProfileInfo = async () => {
    try {
        const result = await $fetch<Api.Responses.User.SuccessProfile | Api.Responses.User.FailedProfile>(
            endpoints.user.profile.path,
            {
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
                method: 'GET',
            },
        )

        if (result.feedback === 'data_success') {
            AuthStore.setUser(result.data.user)
        }
    } catch (e) {
        console.log('Fail on load profile: ', { e })
    }
}

watch(userAvatar, async (value) => {
    if (value) {
        RootStore.setLoadingStatus(true)

        try {
            const formData = new FormData()

            formData.append('image', value, value.name)

            const result = await $fetch<
                Api.Responses.User.SuccessUpdateAvatar | Api.Responses.User.FailedUpdateAvatar
            >(endpoints.user.updateAvatar.path, {
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
                method: 'POST',
                onResponse() {
                    AuthStore.updateActivitiesCount('notifications')
                },
                body: formData,
            })

            if (result.feedback === 'data_updated_success') {
                await updateProfileInfo()

                RootStore.setSiteNotification({
                    type: 'success',
                    duration: 4000,
                    text: $lang.pages.dashboardAjustes.success_account_image,
                })
            } else {
                RootStore.setLoadingStatus(false)

                RootStore.setSiteNotification({
                    type: 'error',
                    duration: 4000,
                    text: $lang.pages.dashboardAjustes.error_account_image,
                })
            }
        } catch (e) {
            console.log('Error on update avatar: ', { e })
        }

        RootStore.setLoadingStatus(false)
    }
})
const saveEmailConfig = async (fromURI?: { preferences: {} }) => {
    emailLoading.value = true
    let payload
    if (fromURI?.preferences) {
        payload = fromURI
    } else {
        payload = {
            preferences: form.email
                ? Object.keys(form.email).map((key) => ({
                      action: key,
                      type: form.email[key] ? 1 : 0,
                  }))
                : [],
        }
        payload.preferences = payload.preferences.filter((item) => item.action !== 'undefined')
    }

    const res = await $fetch<Api.Responses.User.PreferencesUpdated>(endpoints.user.emailPreferences.path, {
        headers: buildHeaders(AuthStore.SessionToken),
        baseURL,
        method: 'POST',
        onResponse() {
            AuthStore.updateActivitiesCount('notifications')
        },
        params: {
            type: 'email',
        },
        body: payload,
    })

    if (res.feedback === 'data_updated_success') {
        if (fromURI) {
            form.email[Route.query.action as string] = false
        }

        localStorage.removeItem('action')

        RootStore.setSiteNotification({
            type: 'success',
            duration: 4000,
            text: $lang.pages.dashboardAjustes.success_updating_preferences_email,
        })
    } else {
        RootStore.setSiteNotification({
            type: 'error',
            duration: 4000,
            text: $lang.pages.dashboardAjustes.error_updating_preferences_email,
        })
    }

    emailLoading.value = false
}
const saveWebConfig = async (fromURI?: { preferences: {} }) => {
    webLoading.value = true
    let payload
    if (fromURI?.preferences) {
        payload = fromURI
    } else {
        payload = {
            preferences: form.web
                ? Object.keys(form.web).map((key) => ({
                      action: key,
                      type: form.web[key] ? 1 : 0,
                  }))
                : [],
        }
        payload.preferences = payload.preferences.filter(
            (item) =>
                item.action !== 'undefined' &&
                item.action !== 'web_tagged_in_a_comment' &&
                item.action !== 'web_post_receives_first_comment' &&
                item.action !== 'web_post_receives_comments' &&
                item.action !== 'web_approved_comment' &&
                item.action !== 'web_banned' &&
                item.action !== 'web_published_post' &&
                item.action !== 'web_rejected_post',
        )
    }

    const res = await $fetch<Api.Responses.User.PreferencesUpdated>(endpoints.user.emailPreferences.path, {
        headers: buildHeaders(AuthStore.SessionToken),
        baseURL,
        method: 'POST',
        onResponse() {
            AuthStore.updateActivitiesCount('notifications')
        },
        params: {
            type: 'web',
        },
        body: payload,
    })

    if (res.feedback === 'data_updated_success') {
        if (fromURI) {
            form.web[Route.query.action as string] = false
        }
        localStorage.removeItem('action')
        RootStore.setSiteNotification({
            type: 'success',
            duration: 4000,
            text: $lang.pages.dashboardAjustes.success_updating_preferences_web,
        })
    } else {
        RootStore.setSiteNotification({
            type: 'error',
            duration: 4000,
            text: $lang.pages.dashboardAjustes.error_updating_preferences_web,
        })
    }

    webLoading.value = false
}
onMounted(() => {
    if (Route.hash === '#notificaciones') {
        nextTick(() => Router.replace({ query: { tab: 'notificaciones' } }))
    }

    if (typeof Route.query.action === 'string') {
        form.email[Route.query.action] = false

        nextTick(async () => {
            try {
                /* this.$nuxt.$loading.start() */
                saveEmailConfig()
                /* this.$nuxt.$loading.finish() */
            } catch (e) {
                console.log('Error on save preferences: ', { e })
            }
        })
    }
})

const { googleAuthInit, facebookAuthInit } = useSocialAuth()

const socialSignIn = (strategy: 'google' | 'facebook') => {
    /* this.$nuxt.$loading.start() */
    try {
        if (strategy === 'google') {
            googleAuthInit(async (err, response) => {
                if (err) {
                    return console.log(err)
                } else {
                    if (response) {
                        RootStore.setLoadingStatus(true)
                        const result = await $fetch<SocialAuthResponse>(
                            endpoints.authentication.socialStrategy.google,
                            {
                                method: 'GET',

                                headers: buildHeaders(AuthStore.SessionToken),
                                params: {
                                    access_token: response.access_token,
                                },
                            },
                        )
                        RootStore.setLoadingStatus(false)

                        if (result.feedback === 'token_created_successfull') {
                            setCookies(result.data.expires_at * 1000, result.data.access_token)

                            location?.reload()
                        }
                    }
                }
            })
        }
        if (strategy === 'facebook') {
            facebookAuthInit(async (err, response) => {
                if (err) {
                    return console.log(err)
                } else {
                    if (response) {
                        RootStore.setLoadingStatus(true)
                        const result = await $fetch<SocialAuthResponse>(
                            endpoints.authentication.socialStrategy.facebook,
                            {
                                method: 'GET',

                                headers: buildHeaders(AuthStore.SessionToken),
                                body: {
                                    access_token: response.authResponse.accessToken,
                                },
                            },
                        )
                        RootStore.setLoadingStatus(false)

                        if (result.feedback === 'token_created_successfull') {
                            setCookies(result.data.expires_at * 1000, result.data.access_token)

                            location?.reload()
                        }
                    }
                }
            })
        }
    } catch (e) {
        /* this.$nuxt.$loading.finish() */
    }
}

const sendVerificationMail = async () => {
    verificationButtonState.loading = true
    try {
        const result = await $fetch<Api.Responses.User.SuccessVerificationRequest>(
            endpoints.user.requestAccountVerification.path,
            {
                headers: buildHeaders(AuthStore.SessionToken),
                baseURL,
                method: 'GET',
            },
        )

        if (result.feedback === 'data_updated_success') {
            verificationButtonState.disabled = true
            RootStore.setSiteNotification({
                text: $lang.pages.dashboardAjustes.email_verification_sended,
                duration: 4000,
                type: 'success',
            })
        }
    } catch (e) {
        console.log('Fail on resend email verification: ', { e })
    }
    verificationButtonState.loading = false
}
const deleteAccount = () => {
    RootStore.setGenericModal({
        type: 'confirm',
        info: {
            text: $lang.pages.dashboardAjustes.are_sure_delete_account,
            continue: {
                text: $lang.pages.dashboardAjustes.delete_account,
                handler: async () => {
                    RootStore.setLoadingStatus(true)

                    try {
                        const result = await $fetch<Api.Responses.User.SuccessClose>(
                            endpoints.user.deleteAccount.path.replace('_ID_', String(AuthStore.UserData?.id)),
                            {
                                headers: buildHeaders(AuthStore.SessionToken),
                                baseURL,
                                method: 'DELETE',
                            },
                        )

                        if (result.feedback === 'closed_account') {
                            location.pathname = '/'
                        } else {
                            RootStore.setSiteNotification({
                                duration: 4000,
                                text: $lang.pages.dashboardAjustes.error_deleting_account,
                                type: 'error',
                            })
                            RootStore.setLoadingStatus(false)
                        }
                    } catch (e) {
                        RootStore.setSiteNotification({
                            duration: 4000,
                            text: $lang.pages.dashboardAjustes.error_deleting_account,
                            type: 'error',
                        })
                        RootStore.setLoadingStatus(false)
                    }
                },
            },
            cancel: {
                text: $lang.pages.dashboardAjustes.cancel,
                handler: () => null,
            },
        },
    })
}
</script>

<style lang="postcss" scoped>
.dashboard-user-config {
    @apply mb-10 p-2 lg:mb-0;
    .account-config {
        @apply space-y-6 px-5 py-2;
        &__delete-account {
            @apply flex items-center text-sm text-site-primary;
            img {
                @apply mr-2 w-4;
            }
        }

        .data-field {
            @apply space-y-6;
            &__send-verification-button {
                @apply flex items-center space-x-3;
                .verification-button {
                    @apply bg-site-primary text-white disabled:bg-gray-300;
                }
                .verification-loading {
                    @apply block h-6 w-6;
                }
            }
            &__item {
                .item {
                    &__head {
                        @apply flex w-full items-center justify-between space-x-2 text-left;
                    }
                    &__info {
                        @apply space-y-1;
                    }
                    &__value-button {
                        @apply flex items-center space-x-2 text-xs text-gray-800 disabled:opacity-80;
                        img {
                            @apply h-4 w-4;
                        }
                    }
                    &__body {
                        @apply pt-3;
                    }
                    &__input-label {
                        @apply mb-1 text-sm text-gray-800;
                    }
                    &__input-field {
                        @apply mb-2 max-w-xs text-sm;
                        &:not(:last-of-type) {
                            @apply mb-3;
                        }
                    }
                    &__feedback {
                        @apply text-[10px] text-gray-800;
                        img {
                            @apply mr-1 inline-block h-3 w-auto align-top opacity-50 grayscale;
                        }
                    }
                    &__button {
                        @apply block rounded-xl bg-[#F4F4F9] px-6 py-2 text-sm text-site-primary;
                    }
                }
            }
        }
    }
    .notifications-config {
        &__tabs {
            @apply my-2 mb-1 mt-3 flex justify-center gap-1.5 px-1 md:px-3 lg:justify-start;
        }
        &__btn {
            @apply flex flex-1 items-center justify-center gap-1.5 rounded-full p-1.5 py-3 text-xs duration-200 md:text-sm lg:flex-initial lg:px-5 lg:hover:brightness-105;
        }
        &__content {
            @apply space-y-2 p-2 md:space-y-0;
        }
        &__heading--primary {
            @apply text-lg md:px-3 md:py-2;
        }
        &__section {
            @apply pb-6 md:px-3;
        }
        &__heading--secondary {
            @apply mb-2 text-base font-semibold text-gray-800 md:mb-3 lg:text-lg;
        }
        &__heading--description {
            @apply text-[#606060];
        }
        &__advice {
            @apply mt-3 text-sm text-[#606060];
        }
        &__telegram {
            @apply mt-3 flex items-center justify-center gap-2 rounded-lg bg-[#29A9EB] p-4 px-10 text-white lg:w-fit;
        }
        &__inner-text {
            @apply mr-5 cursor-pointer text-sm text-[#606060] sm:text-base;
        }
        &__inner-check {
            @apply justify-between;
            &:not(:last-of-type) {
                @apply mb-4;
            }
        }
        &__button {
            @apply flex w-full justify-center pb-4 lg:justify-end lg:pr-4;
            button {
                @apply rounded-xl bg-site-primary p-3 px-10 text-white;
            }
        }
    }
    .social-config {
        @apply mx-auto max-w-md space-y-3 px-2 pb-10 pt-3;
        &__title {
            @apply font-medium text-[#6B6870];
        }
        &__google-button {
            @apply w-full text-[#404040] md:h-16 md:text-2xl;
            span {
                @apply md:h-10 md:w-10 !important;
            }
        }
        &__facebook-button {
            @apply w-full border-facebook bg-facebook text-white md:h-16 md:text-2xl;
            span {
                @apply md:h-10 md:w-10 !important;
            }
        }
        &__is-connected {
            @apply flex items-center gap-1 text-xs text-[#404040];
            @apply mt-1 !important;
            img {
                @apply ml-1 inline-block h-3 w-3;
            }
        }
        &__save-button {
            @apply w-full bg-site-background;
        }
    }
    .save-config {
        @apply mx-auto my-5 border-site-primary bg-site-primary px-10 text-lg font-medium text-white;
    }
}
</style>
