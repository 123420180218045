<template>
    <div class="form-field-toggle">
        <label class="form-field-toggle__container">
            <slot @click="$emit('update:modelValue', !value)"></slot>
            <div class="relative">
                <input
                    v-bind="$attrs"
                    :id="id"
                    :checked="modelValue"
                    type="checkbox"
                    class="sr-only"
                    @click="$emit('update:modelValue', !modelValue)"
                />
                <div
                    class="form-field-toggle__line"
                    :class="modelValue ? 'bg-site-primary' : 'bg-[#E3E4E5]'"
                ></div>
                <div class="form-field-toggle__dot"></div>
            </div>
        </label>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    name: 'FormFieldToggle',
    props: {
        modelValue: Boolean,
        label: String,
        id: String,
    },
})
</script>

<style lang="postcss" scoped>
input:checked ~ .dot {
    @apply translate-x-full;
}
.form-field-toggle {
    @apply flex;
    &__container {
        @apply flex w-full cursor-pointer items-center justify-between;
    }
    &__line {
        @apply block h-6 w-10 rounded-full;
    }
    &__dot {
        @apply dot absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition;
    }
}
</style>
